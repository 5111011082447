import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";


const PreLoad = () => {
   

    return (
        <div>
            
        </div>
    )
}

export default PreLoad;