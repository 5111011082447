import React from "react";

const Schedule = () => {
    return (
        <div>
            <h1>Schedule</h1>
        </div>
    );
}


export default Schedule;
